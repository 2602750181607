import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
export default function Secfour() {
  return (
    <>
      <Container fluid className="__bg-secondary-sl">
        <Container>
          <Row className="fade-in">
            <Col lg={12}>
              <div className="__wrappe-headline-sl">
                <h2 className="text-center">Pilih Durasi Membershipmu</h2>
              </div>
            </Col>
          </Row>
          {/* <Row className="fade-in __margin-top-sl-50">
            <Col lg={4} md={4}>
              <div className="card __margin-bottom-50">
                <img
                  className="card-img-top img img-fluid __card-top-width"
                  src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovetraining.id/attraction-mastery/1daftar-min-v.png"
                  alt="Card image cap"
                />
                <div className="card-body">
                  <h3 className="card-title">Langkah #1</h3>
                  <p className="card-text"> Klik salah satu tombol daftar</p>
                </div>
              </div>
            </Col>
            <Col lg={4} md={4}>
              <div className="card __margin-bottom-50">
                <img
                  className="card-img-top img img-fluid __card-top-width"
                  src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovetraining.id/attraction-mastery/1daftar-kelas-min-v.png"
                  alt="Card image cap"
                />
                <div className="card-body">
                  <h3 className="card-title">Langkah #2</h3>
                  <p className="card-text">
                    {" "}
                    Pilih salah satu kelas : VIP atau Reguler
                  </p>
                </div>
              </div>
            </Col>
            <Col lg={4} md={4}>
              <div className="card ">
                <img
                  className="card-img-top img img-fluid __card-top-width"
                  src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovetraining.id/attraction-mastery/1isi-form-min-v.png"
                  alt="Card image cap"
                />
                <div className="card-body">
                  <h3 className="card-title">Langkah #3</h3>
                  <p className="card-text">
                    {" "}
                    Lakukan pengisian data dengan benar
                  </p>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="fade-in __margin-top-sl-50">
            <Col lg={4} md={4}>
              <div className="card __margin-bottom-50">
                <img
                  className="card-img-top img img-fluid __card-top-width"
                  src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovetraining.id/attraction-mastery/1transfer-min-v.png"
                  alt="Card image cap"
                />
                <div className="card-body">
                  <h3 className="card-title">Langkah #4</h3>
                  <p className="card-text">
                    {" "}
                    Lakukan pembayaran sebelum batas transfer habis
                  </p>
                </div>
              </div>
            </Col>
            <Col lg={4} md={4}>
              <div className="card __margin-bottom-50">
                <img
                  className="card-img-top img img-fluid __card-top-width"
                  src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovetraining.id/attraction-mastery/1konfirmasi.png"
                  alt="Card image cap"
                />
                <div className="card-body">
                  <h3 className="card-title">Langkah #5</h3>
                  <p className="card-text"> Lakukan Konfirmasi pembayaran</p>
                </div>
              </div>
            </Col>
            <Col lg={4} md={4}>
              <div className="card">
                <img
                  className="card-img-top img img-fluid __card-top-width"
                  src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovetraining.id/attraction-mastery/1cek-email-min-v.png"
                  alt="Card image cap"
                />
                <div className="card-body">
                  <h3 className="card-title">Langkah #6</h3>
                  <p className="card-text">
                    {" "}
                    Cek email (inbox/spam/promotion) untuk lihat info link
                    training
                  </p>
                </div>
              </div>
            </Col>
          </Row> */}
          <Row id="buy-up">
            <Col lg={4} md={4}>
              <div className="pricing py-5">
                <div className="card mb-5 mb-lg-0 up-cover">
                  <div className="card-body">
                    <h5 className="card-title text-muted text-uppercase text-center">
                      VIP Membership <br /> 1 BULAN
                    </h5>
                    <h6 className="card-price text-center">
                      <del className="__style-red-text">Rp 212.000</del> <br />{" "}
                      Rp 199.000
                    </h6>
                    <h5 className="card-price-v text-center">Hemat 7%</h5>

                    <hr />
                    <ul className="fa-ul">
                      <li>
                        <span className="fa-li">
                          <FontAwesomeIcon
                            icon={faCheckCircle}
                            className="__fa-check-circle-sl"
                            style={{ color: "green" }}
                          />
                        </span>
                        <b>ALL Access VIP Course</b>
                      </li>

                      <li>
                        <span className="fa-li">
                          <FontAwesomeIcon
                            icon={faCheckCircle}
                            className="__fa-check-circle-sl"
                            style={{ color: "green" }}
                          />
                        </span>
                        <b>ALL Access Premium Course</b>
                      </li>
                      <li>
                        <span className="fa-li">
                          <FontAwesomeIcon
                            icon={faCheckCircle}
                            className="__fa-check-circle-sl"
                            style={{ color: "green" }}
                          />
                        </span>
                        <b>ALL Access FREE Course​</b>
                      </li>
                      <li>
                        <span className="fa-li">
                          <FontAwesomeIcon
                            icon={faCheckCircle}
                            className="__fa-check-circle-sl"
                            style={{ color: "green" }}
                          />
                        </span>
                        <b>Monthly QnA With Coaches​</b>
                      </li>
                      <li>
                        <span className="fa-li">
                          <FontAwesomeIcon
                            icon={faCheckCircle}
                            className="__fa-check-circle-sl"
                            style={{ color: "green" }}
                          />
                        </span>
                        <b>Exclusive Live Webinar​</b>
                      </li>
                      <li>
                        <span className="fa-li">
                          <FontAwesomeIcon
                            icon={faCheckCircle}
                            className="__fa-check-circle-sl"
                            style={{ color: "green" }}
                          />
                        </span>
                        <b>Support system by LavSquad​</b>
                      </li>
                      <li>
                        <span className="fa-li">
                          <FontAwesomeIcon
                            icon={faCheckCircle}
                            className="__fa-check-circle-sl"
                            style={{ color: "green" }}
                          />
                        </span>
                        <b>Group Challenge​​</b>
                      </li>
                    </ul>
                    <h5 className="card-price-v-v text-center">
                      Semua benefit diatas selama 1 bulan
                    </h5>
                    <a
                      href="https://cart.lovecoach.id/product/vip-1-bulan-thr-spesial-offer"
                      target="_blank"
                      className="btn btn-block btn-primary text-uppercase btn-pop-hover"
                    >
                      Join Sekarang
                    </a>
                    {/* <a
                      href="https://wa.link/14x82j"
                      target="_blank"
                      className="btn btn-block btn-success text-uppercase btn-pop-hover"
                    >
                      Chat Cs Disini
                    </a> */}
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={4} md={4}>
              <div className="pricing py-5">
                <div className="card mb-5 mb-lg-0 up-cover">
                  <div className="card-body">
                    <h5 className="card-title text-muted text-uppercase text-center">
                      VIP Membership <br /> 3 BULAN
                    </h5>
                    <h6 className="card-price text-center">
                      <del className="__style-red-text">Rp 636.000</del> <br />{" "}
                      Rp 499.000
                    </h6>
                    <h5 className="card-price-v text-center">Hemat 20%</h5>

                    <hr />
                    <ul className="fa-ul">
                      <li>
                        <span className="fa-li">
                          <FontAwesomeIcon
                            icon={faCheckCircle}
                            className="__fa-check-circle-sl"
                            style={{ color: "green" }}
                          />
                        </span>
                        <b>ALL Access VIP Course</b>
                      </li>

                      <li>
                        <span className="fa-li">
                          <FontAwesomeIcon
                            icon={faCheckCircle}
                            className="__fa-check-circle-sl"
                            style={{ color: "green" }}
                          />
                        </span>
                        <b>ALL Access Premium Course</b>
                      </li>
                      <li>
                        <span className="fa-li">
                          <FontAwesomeIcon
                            icon={faCheckCircle}
                            className="__fa-check-circle-sl"
                            style={{ color: "green" }}
                          />
                        </span>
                        <b>ALL Access FREE Course​</b>
                      </li>
                      <li>
                        <span className="fa-li">
                          <FontAwesomeIcon
                            icon={faCheckCircle}
                            className="__fa-check-circle-sl"
                            style={{ color: "green" }}
                          />
                        </span>
                        <b>Monthly QnA With Coaches​</b>
                      </li>
                      <li>
                        <span className="fa-li">
                          <FontAwesomeIcon
                            icon={faCheckCircle}
                            className="__fa-check-circle-sl"
                            style={{ color: "green" }}
                          />
                        </span>
                        <b>Exclusive Live Webinar​</b>
                      </li>
                      <li>
                        <span className="fa-li">
                          <FontAwesomeIcon
                            icon={faCheckCircle}
                            className="__fa-check-circle-sl"
                            style={{ color: "green" }}
                          />
                        </span>
                        <b>Support system by LavSquad​</b>
                      </li>
                      <li>
                        <span className="fa-li">
                          <FontAwesomeIcon
                            icon={faCheckCircle}
                            className="__fa-check-circle-sl"
                            style={{ color: "green" }}
                          />
                        </span>
                        <b>Group Challenge​​</b>
                      </li>
                    </ul>
                    <h5 className="card-price-v-v text-center">
                      Semua benefit diatas selama 3 bulan
                    </h5>
                    <a
                      href="https://cart.lovecoach.id/product/vip-3-bulan-thr-spesial-offer"
                      target="_blank"
                      className="btn btn-block btn-primary text-uppercase btn-pop-hover"
                    >
                      Join Sekarang
                    </a>
                    {/* <a
                      href="https://wa.link/14x82j"
                      target="_blank"
                      className="btn btn-block btn-success text-uppercase btn-pop-hover"
                    >
                      Chat Cs Disini
                    </a> */}
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={4} md={4}>
              <div className="pricing py-5">
                <div className="card mb-5 mb-lg-0 up-cover">
                  <div className="card-body">
                    <h5 className="card-title text-muted text-uppercase text-center">
                      VIP Membership <br /> 12 BULAN
                    </h5>
                    <h6 className="card-price text-center">
                      <del className="__style-red-text">Rp 2.200.000</del>{" "}
                      <br /> Rp 1.499.000
                    </h6>
                    <h5 className="card-price-v text-center">Hemat 30%</h5>

                    <hr />
                    <ul className="fa-ul">
                      <li>
                        <span className="fa-li">
                          <FontAwesomeIcon
                            icon={faCheckCircle}
                            className="__fa-check-circle-sl"
                            style={{ color: "green" }}
                          />
                        </span>
                        <b>ALL Access VIP Course</b>
                      </li>

                      <li>
                        <span className="fa-li">
                          <FontAwesomeIcon
                            icon={faCheckCircle}
                            className="__fa-check-circle-sl"
                            style={{ color: "green" }}
                          />
                        </span>
                        <b>ALL Access Premium Course</b>
                      </li>
                      <li>
                        <span className="fa-li">
                          <FontAwesomeIcon
                            icon={faCheckCircle}
                            className="__fa-check-circle-sl"
                            style={{ color: "green" }}
                          />
                        </span>
                        <b>ALL Access FREE Course​</b>
                      </li>
                      <li>
                        <span className="fa-li">
                          <FontAwesomeIcon
                            icon={faCheckCircle}
                            className="__fa-check-circle-sl"
                            style={{ color: "green" }}
                          />
                        </span>
                        <b>Monthly QnA With Coaches​</b>
                      </li>
                      <li>
                        <span className="fa-li">
                          <FontAwesomeIcon
                            icon={faCheckCircle}
                            className="__fa-check-circle-sl"
                            style={{ color: "green" }}
                          />
                        </span>
                        <b>Exclusive Live Webinar​</b>
                      </li>
                      <li>
                        <span className="fa-li">
                          <FontAwesomeIcon
                            icon={faCheckCircle}
                            className="__fa-check-circle-sl"
                            style={{ color: "green" }}
                          />
                        </span>
                        <b>Support system by LavSquad​</b>
                      </li>
                      <li>
                        <span className="fa-li">
                          <FontAwesomeIcon
                            icon={faCheckCircle}
                            className="__fa-check-circle-sl"
                            style={{ color: "green" }}
                          />
                        </span>
                        <b>Group Challenge​​</b>
                      </li>
                    </ul>
                    <h5 className="card-price-v-v text-center">
                      Semua benefit diatas selama 12 bulan
                    </h5>
                    <a
                      href="https://cart.lovecoach.id/product/vip-12-bulan-thr-spesial-offer"
                      target="_blank"
                      className="btn btn-block btn-primary text-uppercase btn-pop-hover"
                    >
                      Join Sekarang
                    </a>
                    {/* <a
                      href="https://wa.link/14x82j"
                      target="_blank"
                      className="btn btn-block btn-success text-uppercase btn-pop-hover"
                    >
                      Chat Cs Disini
                    </a> */}
                  </div>
                </div>
              </div>
            </Col>
            {/* <Col lg={6} md={6}>
              <div className="pricing py-5">
                <div className="card mb-5 mb-lg-0">
                  <div className="card-body">
                    <h5 className="card-title text-muted text-uppercase text-center">
                      Regular Class
                    </h5>
                    <h6 className="card-price text-center">
                      <del className="__style-red-text">Rp 499.000</del> <br />{" "}
                      Rp 299.000
                    </h6>
                    <h5 className="card-price-v text-center">DISC 40%</h5>
                    <h5 className="card-price-v-v text-center">
                      Diadakan Hanya 1 Tahun Sekali
                    </h5>
                    <hr />
                    <ul className="fa-ul">
                      <li>
                        <span className="fa-li">
                          <FontAwesomeIcon
                            icon={faCheckCircle}
                            className="__fa-check-circle-sl"
                            style={{ color: "green" }}
                          />
                        </span>
                        3 Jam Online Training (via Webinar Jam){" "}
                        <b>Sabtu, 19 Juni 2021 Pukul 9 - 12 WIB</b>
                      </li>
                      <li>
                        <span className="fa-li">
                          <FontAwesomeIcon
                            icon={faCheckCircle}
                            className="__fa-check-circle-sl"
                            style={{ color: "green" }}
                          />
                        </span>
                        <b>Workbook</b>
                      </li>
                      <li>
                        <span className="fa-li">
                          <FontAwesomeIcon
                            icon={faCheckCircle}
                            className="__fa-check-circle-sl"
                            style={{ color: "green" }}
                          />
                        </span>
                        <b>Akses audio rekaman selama 3 hari</b>
                      </li>
                    </ul>
                    <a
                      href="https://JoseAditya.zaxaa.com/o/14606878858217/1"
                      target="_blank"
                      className="btn btn-block btn-primary text-uppercase"
                    >
                      Daftar Reg Class
                    </a>
                    <a
                      href="https://wa.link/pise9p"
                      target="_blank"
                      className="btn btn-block btn-success text-uppercase"
                    >
                      Chat Cs Disini
                    </a>
                  </div>
                </div>
              </div>
            </Col> */}
          </Row>
          <Row className="fade-in __margin-top-sl-100-mirror">
            <Col lg={6} className="order-lg-last order-md-last">
              <div className="__wrapper-img-headline-sl-v1">
                <img
                  src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovetraining.id/attraction-mastery/girl-v4.png"
                  alt="image cm"
                  className="img img-fluid lazyload"
                />
              </div>
            </Col>

            <Col lg={6}>
              <div className="__wrappe-headline-sl">
                <p>
                  Ladies, beberapa dari kalian pasti pernah bertanya-tanya..
                </p>

                <p>
                  “Mereka kok bisa ya kenalannya gak lama tapi langsung menikah”
                </p>

                <p>
                  “Kenapa pasanganku belum juga ngajak nikah padahal udah
                  pacaran lama..”
                </p>
                <p>
                  “Mau bilang ke dia tentang arah hubungan mau dibawa kemana,
                  tapi takut dia ngerasa ditagih dan malah ninggalin..hiks..”
                </p>
                <p>
                  Pada akhirnya kita cuma bisa diam, pasrah.. Lalu menunggu
                  kepastian.
                </p>
                <p>
                  Senyaman-nyaman-nya menjalani hubungan, wanita pasti
                  menanyakan kepastian..
                </p>
                <p>
                  Mulai dari pertanyaan yang halus seperti: kapan kita nikah?,
                  hubungan kita mau dibawa kemana,
                </p>
                <p>
                  Sampai yang berupa ancaman seperti “pokoknya aku gak mau tau
                  nikahin aku tahun ini!”
                </p>
                <p>
                  Tapi tanpa rasa bersalah dia bilang: “SORI.. AKU BELUM SIAP”
                </p>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <div className="__wrappe-headline-sl">
                <p>
                  Mungkin kamu mulai merasa marah, menyesal atau gak terima
                  dengan jawabannya barusan.
                </p>
                <p>Kenyataannya, meskipun kalian berdua merasa cocok,</p>
                <p>Dia belum siap masuk ke fase yang lebih serius, sekarang.</p>
                <p>
                  Kabar gembiranya adalah: Kamu bisa membuat dia berubah
                  pikiran.
                </p>
                <p>
                  Karenanya, izinkan saya sharing{" "}
                  <b>
                    sebuah cara yang bisa membantumu keluar dari kondisi
                    frustasi ini.
                  </b>
                </p>
                <p>
                  Saat kamu bisa membuat dia paham, bahwa kehadiranmu tidak akan
                  merusak rencana hidup mereka, maka Kamu bukan lagi dianggap
                  “Wanita yang Tepat Di Waktu yang Salah”
                </p>
                <p>
                  Tapi... <br /> Wanita yang Tepat Di Waktu yang Tepat...
                </p>
                <p>
                  Sampai jumpa di Online Training: <br />{" "}
                  <b>Commitment Mastery</b>
                </p>
                <p>
                  <b>
                    Teknik Agar Pria Mau Komit <br />
                    Tanpa Paksaan Tanpa Pertengkaran
                  </b>
                </p>
                <p>
                  Sehingga kamu bisa menguasai teknik bicara yang elegan &
                  efektif tanpa bikin calonmu merasa “ditagih”.
                </p>
                <p>
                  ​ Dan bisa{" "}
                  <b>terhindar dari cara yang salah dan malah jadi bubar.</b>
                </p>
                <p>
                  ​ Pada akhirnya, kamu{" "}
                  <b>tidak lagi terjebak didalam hubungan yang tanpa arah </b>{" "}
                  ataupun menjalani hubungan dengan{" "}
                  <b>pria modus yang tidak mau berkomitmen.</b>
                </p>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={12} md={12} sm={12} xs={12}>
              <div className="__bg-white-rounded-sl __margin-top-sl-50">
                <Row className="fade-in">
                  <Col lg={12}>
                    <div className="__wrappe-headline-sl">
                      <h2>Kini Saatnya Kamu Berhenti Dari Kesalahan Ini:</h2>
                    </div>
                  </Col>

                  <Col lg={12} md={12} sm={12} xs={12}>
                    <div className="d-flex">
                      <div className="__width-icon-and-text">
                        <FontAwesomeIcon
                          icon={faTimesCircle}
                          className="__fa-check-circle-sl"
                          style={{ color: "red" }}
                        />
                      </div>
                      <p className="__text-desc-icon-sl">
                        Terjebak dalam hubungan yang tanpa arah
                      </p>
                    </div>
                  </Col>

                  <Col lg={12} md={12} sm={12} xs={12}>
                    <div className="d-flex">
                      <div className="__width-icon-and-text">
                        <FontAwesomeIcon
                          icon={faTimesCircle}
                          className="__fa-check-circle-sl"
                          style={{ color: "red" }}
                        />
                      </div>
                      <p className="__text-desc-icon-sl">
                        Dipermainkan oleh cowok modus
                      </p>
                    </div>
                  </Col>

                  <Col lg={12} md={12} sm={12} xs={12}>
                    <div className="d-flex">
                      <div className="__width-icon-and-text">
                        <FontAwesomeIcon
                          icon={faTimesCircle}
                          className="__fa-check-circle-sl"
                          style={{ color: "red" }}
                        />
                      </div>
                      <p className="__text-desc-icon-sl">
                        Mengungkapkan perasaan tentang berkomitmen tanpa
                        persiapan
                      </p>
                    </div>
                  </Col>

                  <Col lg={12} md={12} sm={12} xs={12}>
                    <div className="d-flex">
                      <div className="__width-icon-and-text">
                        <FontAwesomeIcon
                          icon={faTimesCircle}
                          className="__fa-check-circle-sl"
                          style={{ color: "red" }}
                        />
                      </div>
                      <p className="__text-desc-icon-sl">
                        Tidak paham mengartikan makna komitmen
                      </p>
                    </div>
                  </Col>

                  <Col lg={12} md={12} sm={12} xs={12}>
                    <div className="d-flex">
                      <div className="__width-icon-and-text">
                        <FontAwesomeIcon
                          icon={faTimesCircle}
                          className="__fa-check-circle-sl"
                          style={{ color: "red" }}
                        />
                      </div>
                      <p className="__text-desc-icon-sl">
                        Merasa takut ditinggal pria karena takut salah berbicara
                      </p>
                    </div>
                  </Col>

                  <Col lg={12}>
                    <div className="__wrappe-headline-sl">
                      <p>
                        Jika Kamu ingin terhindar dari hubungan tanpa arah
                        dengan asas “Kita Jalanin Dulu Aja” dan ingin buat dia
                        berkomitmen bukan hanya perkataan tapi juga
                        membuktikannya ucapannya.
                      </p>
                      <p>
                        Tidak ada alasan untuk tidak segera kuasai semua
                        tekniknya di <br />
                        <b>Commitment Mastery</b>
                      </p>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </Container>
    </>
  );
}
